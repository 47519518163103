import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';

let middlewares = [...getDefaultMiddleware({ serializableCheck: false })];

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;

export * from "./reducers";
export * from "./selectors";
export * from "./Slice";