import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CatalogAPI, ConfigurationsAPI } from "../../services/axios";
import { IPromotionTermsModal, TGlobalLayout } from "../../models";
import { setLocalStorage, getLocalStorage } from "../../utilities";

const CK_LAYOUT_KEY = "ck_layout";

type CatalogsState = {
  /* 
  workshopTypes: WorkshopType[] | undefined;
  serviceType: ServiceType[];
  days: Day[];
  services: WorkshopService[];
  serviceCategories: ServiceCategory[];
  drawerLoad: boolean;
  */
  globalLayout: TGlobalLayout | undefined;
  sasToken: any;
  processingForm: boolean;
  packageTypes: any[];
  status: "loading" | "succeeded" | "failed";
  error?: string; 
  promotionTermsModal: IPromotionTermsModal;
  navigationSelectedKeys: string[];
};

const initialState: CatalogsState = {
  /* 
  workshopTypes: [],
  serviceType: [],
  days: [],
  services: [],
  serviceCategories: [],
  drawerLoad: false,
  */
  globalLayout: getLocalStorage(CK_LAYOUT_KEY) || 'default',
  sasToken: null,
  processingForm: false,
  packageTypes: [],
  status: "loading",
  error: undefined,
  promotionTermsModal: {
    show: false,
    terms: [],
    title: ""
  },
  navigationSelectedKeys: []
};

export const serviceSAS = createAsyncThunk('catalogs/serviceSas', async () => {
  const response = await ConfigurationsAPI.getSasTokenNonRegistered();
  return response.data as any;
});

export const fetchPublicCatalogs = createAsyncThunk(
  "catalogs/fetchPublicCatalogs",
  async (language: string) => {
    const [
      /* 
      typesResponse,
      serviceType,
      daysResponse,
      servicesResponse,
      serviceCategoriesResponse, 
      */
      //packagesResponse,
      packageDetailResponse,
    ] = await Promise.all([
      /* 
      CatalogAPI.getWorkshopTypes(language),
      CatalogAPI.getServiceType(language),
      CatalogAPI.getDays(),
      CatalogAPI.getServices(language),
      CatalogAPI.getWorkshopServicesType(language), 
      */
      CatalogAPI.getPackagesDetail(),
    ] as const);
    return {
      /* 
      types: typesResponse.data,
      serviceType: serviceType.data,
      serviceCategories: serviceCategoriesResponse.data,
      days: daysResponse.data,
      services: servicesResponse.data, 
      */  
      //packageTypes: packagesResponse.data,
      packageTypes: packageDetailResponse.data,
    };
  }
);

export const fetchPackageTypes = createAsyncThunk(
  "catalogs/fetchPackageTypes",
  async () => {
    const packageResponse = await CatalogAPI.getPackagesDetail();
    return packageResponse.data;
  }
);

/* 
export const fetchWorkshopServicesType = createAsyncThunk(
  "catalogs/fetchWorkshopServicesType",
  async (payload: { language: string; serviceTypeCode: string[] }) => {
    const types = payload.serviceTypeCode.join();
    const serviceCategoriesResponse = await CatalogAPI.getWorkshopServicesType(
      payload.language,
      types
    );
    return serviceCategoriesResponse.data;
  }
); 
*/

/* 
export const loadDrawer = createAsyncThunk("catalogs/loadDrawer", async () => {
  return true;
}); 
*/

const PublicCatalogSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    /* 
    setWorkshopTypes(state, action) {
      state.workshopTypes = action.payload;
    },
    */
    setProcessingForm(state, action: { payload: boolean}) {
      state.processingForm = action.payload;
    },
    setPromotionTermsModal(state, action: { payload: IPromotionTermsModal}) {
      state.promotionTermsModal = action.payload;
    },
    showPromotionTermsModal(state) {
      state.promotionTermsModal.show = true;
    },
    hidePromotionTermsModal(state) {
      state.promotionTermsModal.show = false;
    },
    setGlobalLayout(state, action: { payload: TGlobalLayout}) {
      state.globalLayout = action.payload;
      setLocalStorage(CK_LAYOUT_KEY, action.payload);
    },
    setNavigationSelectedKeys(state, action: { payload: string[]}) {
      state.navigationSelectedKeys = action.payload;
    }
  },
  extraReducers: (builder) => {
    /* Fetch all catalogs chunk */
    builder.addCase(fetchPublicCatalogs.fulfilled, (state, { payload }) => {
      const {
        /* 
        types,
        serviceType,
        services,
        serviceCategories, 
        */
        packageTypes,
      } = payload;
      /* 
      state.workshopTypes = types;
      state.serviceType = serviceType.sort((a, b) => {
        if (a.typePosition - b.typePosition >= 0) return 1;
        if (a.typePosition - b.typePosition < 0) return -1;
        return 0;
      });
      // state.services = services.sort((a, b) => {
      //   if (a.serviceName > b.serviceName) return 1;
      //   if (a.serviceName < b.serviceName) return -1;
      //   return 0;
      // });
      state.serviceCategories = serviceCategories.sort((a, b) => {
        if (a.serviceCategoryName > b.serviceCategoryName) return 1;
        if (a.serviceCategoryName < b.serviceCategoryName) return -1;
        return 0;
      }); 
      */
      state.packageTypes = packageTypes;
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(fetchPublicCatalogs.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined; 
    });

    builder.addCase(fetchPublicCatalogs.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs"; 
    });

    /* Fetch  packages chunk */
    builder.addCase(fetchPackageTypes.fulfilled, (state, { payload }) => {
      state.packageTypes = payload;
      state.status = "succeeded";
      state.error = undefined;
    });
    builder.addCase(fetchPackageTypes.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined; 
    });

    builder.addCase(fetchPackageTypes.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs"; 
    });

    /* Fetch sastoken chunk */
    builder.addCase(serviceSAS.fulfilled, (state, { payload }) => {
      state.sasToken = payload;
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(serviceSAS.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined; 
    });

    builder.addCase(serviceSAS.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load sasToken"; 
    });

    /* 
    builder.addCase(fetchWorkshopServicesType.fulfilled, (state, { payload }) => {
      state.serviceCategories = payload;
    });
    builder.addCase(fetchWorkshopServicesType.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined; 
    });
    builder.addCase(fetchWorkshopServicesType.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs"; 
    });

    builder.addCase(loadDrawer.fulfilled, (state, { payload }) => {
      state.drawerLoad = payload;
    });
    builder.addCase(loadDrawer.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined;
    });

    builder.addCase(loadDrawer.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs";
    });
    */

  }
});

export const { 
  /* setWorkshopTypes, */
  setProcessingForm,
  setPromotionTermsModal,
  showPromotionTermsModal,
  hidePromotionTermsModal,
  setGlobalLayout,
  setNavigationSelectedKeys
} = PublicCatalogSlice.actions;
export default PublicCatalogSlice.reducer;
