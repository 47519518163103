import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IPFilterWorkshop, WorkshopPublicBasicInfo } from "../../models";
import { WorkshopAPI } from "../../services/axios";

type FilterHelperSlice = {
  workshopsFiltered: WorkshopPublicBasicInfo[] | null;
  coordinates: string | undefined;
  status: "loading" | "succeeded" | "failed";
  error?: string;
};

let initialState: FilterHelperSlice = {
  workshopsFiltered: [],
  coordinates: undefined,
  status: "loading",
  error: undefined,
};

interface ISearchWorshopFiltered {
  language?: string;
  filters: IPFilterWorkshop;
  filter?: boolean;
  bestFilter?: boolean;
}

export const fetchWorkshopFiltered = createAsyncThunk(
  "filters/fetchWorkshopFiltered",
  async ({ language, filters, filter, bestFilter }: ISearchWorshopFiltered) => {
    let response = await WorkshopAPI.search({
      ...filters,
      userTimeZone: new Date().getTimezoneOffset().toString(),
    });
    if (response.status !== 200) return [];
    let temp = response.data;
    if (filter) {
      temp = temp.filter((ws) => ws.isOpen !== "False");
    }
    if (bestFilter) {
      temp = temp.filter((ws) => {
        const rate =
          ws.workshopRating.reduce(
            (prev, curr) => parseFloat(curr.rating) + prev,
            0
          ) / ws.workshopRating.length;
        return rate >= 4.0;
      });
    }
    return temp;
  }
);

export const fetchWorkshopFilteredPackages = createAsyncThunk(
  "filters/fetchWorkshopFilteredPackages",
  async (packageFilters: IPFilterWorkshop) => {
    let response = await WorkshopAPI.getByPackage({
      ...packageFilters,
    });
    let temp = response.data;
    return temp;
  }
);

const WorkshopFilteredSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    clearWorkshopFiltered(state) {
      state.workshopsFiltered = [];
      state.error = undefined;
    },
    setCoordinates(state, action) {
      state.coordinates = action.payload;
    },
    setWorkshopFiltered(state, action) {
      state.workshopsFiltered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkshopFiltered.fulfilled, (state, { payload }) => {
      state.workshopsFiltered = payload;
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(
      fetchWorkshopFiltered.pending,
      (state, { payload, meta }) => {
        state.workshopsFiltered = null;
        state.status = "loading";
        state.error = undefined;
      }
    );

    builder.addCase(
      fetchWorkshopFiltered.rejected,
      (state, { payload, error }) => {
        state.status = "failed";
        state.error = error.message;
      }
    );

    builder.addCase(
      fetchWorkshopFilteredPackages.fulfilled,
      (state, { payload }) => {
        state.workshopsFiltered = payload;
        state.status = "succeeded";
        state.error = undefined;
      }
    );

    builder.addCase(
      fetchWorkshopFilteredPackages.pending,
      (state, { payload, meta }) => {
        state.workshopsFiltered = null;
        state.status = "loading";
        state.error = undefined;
      }
    );

    builder.addCase(
      fetchWorkshopFilteredPackages.rejected,
      (state, { payload, error }) => {
        state.status = "failed";
        state.error = error.message;
      }
    );
  },
});

export const { clearWorkshopFiltered, setCoordinates, setWorkshopFiltered } = WorkshopFilteredSlice.actions;
export default WorkshopFilteredSlice.reducer;
