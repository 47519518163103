import { combineReducers } from "redux";
import { default as NotificationSlice } from "../Slice/NotificationSlice";
import { default as publicCatalogReducer } from "../Slice/PublicCatalogSlice";
import { default as userStatusReducer } from "../Slice/userStatusSlice";
import { default as WorkshopFinderReducer } from "../Slice/WorkshopFinderSlice";

const rootReducer = combineReducers({
  userStatus: userStatusReducer,
  workshopFinder: WorkshopFinderReducer,
  publicCatalog: publicCatalogReducer,
  notificationAppointment: NotificationSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
