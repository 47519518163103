import { IPackageCategory, IServiceCategory, TxhrStatus, WorkshopServiceType } from "../../models";
import { RootState } from "../../stores";

/**
 * Unused code
 */
/* 
Workshop Store
export const getWorkshops = (state: RootState) => state.workshop.workshops; 
export const getLoading = (state: RootState) => state.workshop.loading;
export const getError = (state: RootState) => state.workshop.error;
export const getAction = (state: RootState) => state.workshop.action;

Catalog Store
export const getWorkshopTypes = (state: RootState) => state.catalog.workshopTypes;
export const getVehicleBrands = (state: RootState) => state.catalog.vehicleBrand;
export const getVehicleModels = (state: RootState) => state.catalog.vehicleModel;
export const getServicesByWorkshopType = (state: RootState) => state.catalog.ServicesByWorkshopType;
export const getServices = (state: RootState) => state.catalog.Services;
export const getRoles = (state: RootState) => state.catalog.Roles;
export const getLoadingCatalog = (state: RootState) => state.catalog.loading;
export const getErrorCatalog = (state: RootState) => state.catalog.error;
export const getDays = (state: RootState) => state.catalog.Days;
export const getQuizSections = (state: RootState) => state.catalog.quizSections;
export const getQuizChecklist = (state: RootState) => state.catalog.quizChecklist;

User Store
export const getUsers = (state: RootState) => state.user.users;
export const getUsersLoading = (state: RootState) => state.user.loading;
export const getUserError = (state: RootState) => state.user.error;
export const getUserAction = (state: RootState) => state.user.action;
export const getUser = (state: RootState) => state.user.user;


Appointments Store
export const getAppointments = (state: RootState) => state.appointment.appointments;
export const getAppointment = (state: RootState) => state.appointment.appointment;
export const getAppointmentLoading = (state: RootState) => state.appointment.loading;
export const getAppointmentError = (state: RootState) => state.appointment.error;
export const getAppointmentStatus = (state: RootState) => state.catalog.appointmentStatus;

Public Catalog Store
export const getPublicWorkshopType = (state: RootState) => state.publicCatalog.workshopTypes;
export const getPublicServiceType = (state: RootState) => state.publicCatalog.serviceType;
export const getPublicWorkshopServicesCategories = (state: RootState) => state.publicCatalog.serviceCategories;
export const getPublicWorkshopServices = (state: RootState) => state.publicCatalog.services;
export const getDrawerLoad = (state: RootState) => state.publicCatalog.drawerLoad;

Workshop Filtered
export const getWorkshopFiltered = (state: RootState) => state.workshopFiltered.workshopsFiltered;
export const getCoordinates = (state: RootState) => state.workshopFiltered.coordinates;
export const getLoadingWorshopFiltered = (state: RootState) => state.workshopFiltered.status;
*/

export const getGlobalLayout = (state: RootState) => state.publicCatalog.globalLayout;
export const getSasToken = (state: RootState) => state.publicCatalog.sasToken;
export const getProcessingForm = (state: RootState) => state.publicCatalog.processingForm;
export const getPublicPackages = (state: RootState) => state.publicCatalog.packageTypes;
export const getPromotionalModal = (state: RootState) => state.publicCatalog.promotionTermsModal;
export const getNavigationSelectedKeys = (state: RootState) => state.publicCatalog.navigationSelectedKeys;

export const getNotificationAppointment = (state: RootState) => state.notificationAppointment.Appoinments;
export const getUserBalance = (state: RootState) => state.notificationAppointment.balance;

export const getWorkshopFinder = (state: RootState) => state.workshopFinder;
export const getFinderCriteria = (state: RootState) : WorkshopServiceType => state.workshopFinder.criteria;
export const getFinderSelectedPackage = (state: RootState) : IPackageCategory => state.workshopFinder.selected.packages;
export const getFinderSelectedServices = (state: RootState) : IServiceCategory => state.workshopFinder.selected.services;
export const getFinderLocation = (state: RootState) => state.workshopFinder.location;
export const getFinderServicesCatalog = (state: RootState): {
  values: IServiceCategory[];
  status: TxhrStatus;
  error?: string;
} => state.workshopFinder.catalog.services;
export const getFinderPackagesCatalog = (state: RootState) : {
  values: IPackageCategory[];
  status: TxhrStatus;
  error?: string;
} => state.workshopFinder.catalog.packages;
export const getFinderVehiclesCatalog = (state: RootState) => state.workshopFinder.catalog.vehicleBrands;
export const getFinderAdditionalInfoCodesCatalog = (state: RootState) => state.workshopFinder.catalog.additionalInfoCodes;
export const getFinderKm = (state: RootState) => state.workshopFinder.km;
export const getFinderStep = (state: RootState) => state.workshopFinder.step;
export const getFinderFilters = (state: RootState) => state.workshopFinder.filters;
export const getFinderSort = (state: RootState) => state.workshopFinder.sort;
export const getContactWorkshopId = (state: RootState) => state.workshopFinder.contactWorkshopId; 

export const getLocalUserState = (state: RootState) => state.userStatus.status;
export const getInactiveAccount = (state: RootState) => state.userStatus.inactiveAccount;
export const getUser = (state: RootState) => state.userStatus.user;

