import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Balance } from "../../models";
import { RatingAPI } from "../../services/axios";

interface AppoinmentNotification {
  status: "loading" | "succeeded" | "failed";
  error?: string; 
  balance?: Balance | null;
  Appoinments: any[];
};

const initialState: AppoinmentNotification = {
  status: "loading",
  error: undefined, 
  Appoinments: [],
  balance: null
};

export const fetchNotificationAppoinment = createAsyncThunk(
  "notifications/appointment",
  async (language: string) => {
    const response = await RatingAPI.getNotifications();
    return response.data;
  }
);

const NotificationAppoinmentSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    clearNotifications(state) {
      state.Appoinments = [];
      state.error = undefined;
    },
    setNotifications(state, action) {
      state.Appoinments = action.payload;
    },
    setBalance(state, action) {
      state.balance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNotificationAppoinment.fulfilled,
      (state, { payload }) => {
        state.Appoinments = payload;
        state.status = "succeeded";
        state.error = undefined; 
      }
    );

    builder.addCase(
      fetchNotificationAppoinment.pending,
      (state, { payload, meta }) => {
        state.status = "loading";
        state.error = undefined;
      }
    );

    builder.addCase(
      fetchNotificationAppoinment.rejected,
      (state, { payload, error }) => {
        state.status = "failed";
        state.error = error.message;
      }
    );
  },
});

export const {
  clearNotifications,
  setNotifications,
  setBalance,
} = NotificationAppoinmentSlice.actions;
export default NotificationAppoinmentSlice.reducer;
