import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from '../../services/axios';
import { message } from "antd";
import { AuthRest } from '../../auth/SignInAuth';
import { User } from '../../models';

/* 
const serviceCarouselOrder = [
  'IDK',
  'MMG',
  'LFyS',
  'Moto',
  'HyP',
  'TyD',
  'BySE',
  'Tunning',
  'MEyR',
  'LEyE',
  'A/C',
  'VVyS',
  'Diesel',
  'Other',
];
*/

const authRest = new AuthRest();

interface Istate {
  user: User | undefined;
  status: 'REGTY' | 'PLSACTIVATE' | 'WELCOME' | undefined;
  inactiveAccount: {
    email: string,
    password: string,
  }
}
const initialState: Istate = {
  user: undefined,
  status: undefined,
  inactiveAccount: {
    email: undefined,
    password: undefined,
  }
};

export const fetchUserStatus = createAsyncThunk(
  'userStatus/fetchUserStatus',
  async (email: string) => {
    const response = await UserAPI.getUserStatus(email);
    if (response.status !== 200) return false;
    return response.data;
  },
);

export const resendVerificationEmail = createAsyncThunk(
    'userStatus/resendVerificationEmail',
    async ( account: {email: string, password: string}) => {
      const response = await UserAPI.resendVerificationEmail(account.email, account.password);
      if (response.status !== 200) return false;
      return response.data;
    },
);

export const confirmUserAccount = createAsyncThunk(
    'userStatus/confirmUserAccount',
    async ( data: {code: string, client_secret: string}) => {
      const response = await UserAPI.updateUserStatus(data.code, data.client_secret);
      if (response.status !== 200) return false;
      return response.data;
    },
);

export const updateStatus = createAsyncThunk('userStatus/updateStatus', async (code: string) => {
  await updateStatus(code);
});

export const setLocalStatus = createAsyncThunk(
  'userStatus/setLocalStatus',
  (value: 'REGTY' | 'PLSACTIVATE' | 'WELCOME' | undefined) => {
    return value;
  },
);

export const setInactiveEmail = createAsyncThunk(
    'userStatus/setInactiveEmail',
    (value: string) => {
      return value;
    },
);

export const setInactivePassword = createAsyncThunk(
    'userStatus/setInactivePassword',
    (password: string) => {
      return password;
    },
);

export const clearInactiveAccount = createAsyncThunk(
    'userStatus/clearInactiveAccount',
    () => {
      return undefined;
    },
);

const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState: initialState,
  reducers: {
    setStatus(state: Istate, action: any) {
      state.status = action;
    },
    setUser(state: Istate, action: {payload: User | undefined}) {
      state.user = action.payload;
    },
    logoutUser(state: Istate) {
      state.user = undefined;
      authRest.logout();
    }
  },
  extraReducers: (builder) => {
    //fetchUserStatus
    builder.addCase(fetchUserStatus.fulfilled, (state, { payload }) => {
      state.status = payload === true ? undefined : 'PLSACTIVATE';
    });
    builder.addCase(fetchUserStatus.pending, (state, {}) => {
      state.status = undefined;
    });
    builder.addCase(fetchUserStatus.rejected, (state, { error }) => {
      state.status = undefined;
    });
    //setLocalStatus
    builder.addCase(setLocalStatus.fulfilled, (state, { payload }) => {
      state.status = payload;
    });
    builder.addCase(setLocalStatus.pending, (state, {}) => {});
    builder.addCase(setLocalStatus.rejected, (state, { error }) => {});
    //setInactiveEmail
    builder.addCase(setInactiveEmail.fulfilled, (state, { payload }) => {
      state.inactiveAccount.email = payload;
    });
    builder.addCase(setInactiveEmail.pending, (state, {}) => {});

    builder.addCase(setInactiveEmail.rejected, (state, { error }) => {});
    //setInactivePassword
    builder.addCase(setInactivePassword.fulfilled, (state, { payload }) => {
      state.inactiveAccount.password = payload;
    });
    builder.addCase(setInactivePassword.pending, (state, {}) => {});
    builder.addCase(setInactivePassword.rejected, (state, { error }) => {});
    //clearInactiveAccount
    builder.addCase(clearInactiveAccount.fulfilled, (state, { payload }) => {
      state.inactiveAccount.password = payload;
      state.inactiveAccount.email = payload;
    });
    builder.addCase(clearInactiveAccount.pending, (state, {}) => {});
    builder.addCase(clearInactiveAccount.rejected, (state, { error }) => {});
    //resendVerificationEmail
    builder.addCase(resendVerificationEmail.fulfilled, (state, { payload }) => {
      if(payload) {
        message.success("Correo reenviado exitosamente");
        state.inactiveAccount.email = undefined;
        state.inactiveAccount.password = undefined;
      } else {
        message.error("Error al reenviar correo")
      }
    });
    builder.addCase(resendVerificationEmail.pending, (state, {}) => {});
    builder.addCase(resendVerificationEmail.rejected, (state, { error }) => {
      message.error("Error al reenviar correo")
    });
    //confirmUserAccount
    builder.addCase(confirmUserAccount.fulfilled, (state, { payload }) => {
      console.log("onFullfilledPayload: ", payload);
      // use token for autologin on confirm
      //if(payload.isConfirmed) {
      //  message.success("Usuario confirmado correctamente");
      //}
      if(payload.isConfirmed && payload?.logInfo?.access_token) {
        const tokens = {
          access_token: payload?.logInfo?.access_token,
          id_token: payload?.logInfo?.access_token,
          refresh_token: payload?.logInfo?.refresh_token
        };
        authRest.setTokens(tokens).then(()=>{
          window.location.reload();
          state.status = "WELCOME";
        });
      }
    });
    builder.addCase(confirmUserAccount.pending, (state, {}) => {});
    builder.addCase(confirmUserAccount.rejected, (state, { error }) => {
      message.error("¡Ups, ocurrió un error al confirmar tu usuario!");
    });
  },
});

export const { setStatus, setUser, logoutUser } = userStatusSlice.actions;
export default userStatusSlice.reducer;
