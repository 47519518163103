import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IServiceCarousel,
  IPackageServiceCombinations,
  IPackageType,
  TxhrStatus,
  TlocationUse,
  IPackageCategory,
  IServiceCategory,
  WorkshopServiceType,
} from "../../models";
import { localStorage } from "../../utilities";

import { CatalogAPI } from "../../services/axios";

export const DEFAULT_FINDER_DISTANCE = 25;

type TSortCriteria = "DISTANCE" | "RATING" | "PRICE_ASC" | "PRICE_DSC";
export interface ISortSelectOptions {
  label: string;
  sortField: TSortCriteria;
}

export const wokrshopFinderSortOptions: ISortSelectOptions[] = [
  {
    label: "Más cercanos",
    sortField: "DISTANCE",
  },
  {
    label: "Mejor calificación",
    sortField: "RATING",
  },
  {
    label: "Menor precio",
    sortField: "PRICE_ASC",
  },
  {
    label: "Mayor precio",
    sortField: "PRICE_DSC",
  },
];

const serviceCarouselOrder = [
  "IDK",
  "MMG",
  "LFyS",
  "Moto",
  "HyP",
  "TyD",
  "BySE",
  "Tunning",
  "MEyR",
  "LEyE",
  "A/C",
  "VVyS",
  "Diesel",
  "Other",
];

interface ISelectedPackage extends IPackageServiceCombinations {
  searchType: IPackageType;
}

interface IFinderFilters {
  additionalInfoCodes: string[];
  workshopBrands: string;
  workshopName: string;
  workshopFranchise: string;
}
interface WorkshopFinder {
  criteria: WorkshopServiceType;
  catalog: {
    packages: {
      values: IPackageCategory[];
      status: TxhrStatus;
      error?: string;
    };
    services: {
      values: IServiceCategory[];
      status: TxhrStatus;
      error?: string;
    };
    additionalInfoCodes: {
      values: {
        code: string;
        description: string;
      }[];
      status: TxhrStatus;
      error?: string;
    };
    vehicleBrands: {
      values: any[];
      status: TxhrStatus;
      error?: string;
    };
  };
  selected: {
    packages: IPackageCategory | undefined;
    services: IServiceCategory | undefined;
  };
  location: {
    use: TlocationUse | undefined;
    searchCoordinates: {
      latitude: string | undefined;
      longitude: string | undefined;
    };
    address: string;
    formatted_address: string;
  };
  filters: {
    additionalInfoCodes: string[];
    workshopBrands: string;
    workshopName: string;
    workshopFranchise: string;
    hasWarranty?: boolean;
  };
  sort: TSortCriteria;
  km: number;
  step: number;
  contactWorkshopId?: string;
}
const initialState: WorkshopFinder = {
  criteria: "MAN",
  catalog: {
    packages: {
      values: [],
      status: "idle",
      error: undefined,
    },
    services: {
      values: [],
      status: "idle",
      error: undefined,
    },
    additionalInfoCodes: {
      values: [
        { code: "INFO0001", description: "Recepción de vehículos 24/7" },
        {
          code: "INFO0002",
          description: "Recolección de vehículo a domicilio",
        },
        { code: "INFO0003", description: "Gestión de servicio de grúa" },
      ],
      status: "idle",
      error: undefined,
    },
    vehicleBrands: {
      values: [],
      status: "idle",
      error: undefined,
    },
  },
  selected: {
    packages: undefined,
    services: undefined,
  },
  location: {
    use: undefined,
    searchCoordinates: {
      latitude: undefined,
      longitude: undefined,
    },
    address: "",
    formatted_address: "",
  },
  filters: {
    additionalInfoCodes: [],
    workshopBrands: "",
    workshopName: "",
    workshopFranchise: "",
  },
  sort: "DISTANCE",
  km: DEFAULT_FINDER_DISTANCE,
  step: 1,
  contactWorkshopId: undefined
};

// Create new storedState object
const storedState = new localStorage("workshopFinder", initialState);
// Let's make sure to store initial state in localStorage if is not initialized yet
storedState.set(storedState.get());

export const storeFetchServicesCarousel = createAsyncThunk(
  "workshopFinder/fetchServicesCarousel",
  async () => {
    const response = await CatalogAPI.fetchServicesCarousel("ES-MX");
    if (response.status !== 200) return [];
    // Sort services
    const newArray: IServiceCarousel[] = [];
    const notFoundedArray: IServiceCarousel[] = [];
    response.data.forEach((service) => {
      let alteredService = { ...service, reason: [] };
      const index = serviceCarouselOrder.indexOf(
        alteredService.serviceTypeCode
      );
      if (index === -1) notFoundedArray.push(alteredService);
      if (index !== -1) newArray[index] = alteredService;
    });
    return [...newArray, ...notFoundedArray]; 
    // return prefetchServices
  }
);

export const storeFetchPackagesCombination = createAsyncThunk(
  "workshopFinder/fetchPackagesCombination",
  async () => {
    const response = await CatalogAPI.fetchPackagesCombination("ES-MX");
    if (response.status !== 200) return [];
    return response.data; 
    // return prefetchPackages;
  }
);

export const storeFetchVehicleBrands = createAsyncThunk(
  "workshopFinder/fetchVehicleBrands",
  async () => {
    const response = await CatalogAPI.getVehicleBrands("ES-MX", "AUTOS");
    if (response.status !== 200) return [];
    return response.data;
  }
);

export const storeFetchAdditionalInfoCodes = createAsyncThunk(
  "workshopFinder/fetchAdditionalInfoCodes",
  async () => {
    return [
      { code: "INFO0001", description: "Recepción de vehículos 24/7" },
      { code: "INFO0002", description: "Recolección de vehículo a domicilio" },
      { code: "INFO0003", description: "Gestión de servicio de grúa" },
    ];
  }
);

/* export const storeKmValue = createAsyncThunk('workshopFinder/fetchKmValue', (value: number) => {
  return value;
}); */

const WorkshopFilteredSlice = createSlice({
  name: "workshopFinder",
  initialState: storedState.get(),
  reducers: {
    setSelectedCriteria(state: Istate, action: { payload: WorkshopServiceType}) {
      state.criteria = action.payload;
      storedState.set(state);
    },
    setSelectedPackage(state: Istate, action: { payload: IPackageCategory}) {
      state.selected.packages = action.payload;
      storedState.set(state);
    },
    clearSelectedPackage(state: Istate) {
      state.selected.packages = undefined;
      storedState.set(state);
    },
    setSelectedService(state: Istate, action: { payload: IServiceCategory}) {
      state.selected.services = action.payload;
      storedState.set(state);
    },
    unsetSelectedService(state: Istate) {
      state.selected.services = undefined;
      storedState.set(state);
    },
    clearSelectedServices(state: Istate) {
      state.selected.services = undefined;
      storedState.set(state);
    },
    toogleSelectedServices(state: Istate, action: { payload: IServiceCategory}) {
      const { payload } = action;
      state.selected.services = payload;
      storedState.set(state);
    },
    setReasonsSelected(state: Istate, action: { payload: IServiceCategory}) {
      const { payload } = action;
      // It will set the selection
      state.selected.services = payload;
      storedState.set(state);
    },
    setLocationToCurrent(state: Istate) {
      state.location.use = "current";
      state.location.address = "Ubicación Actual";
      storedState.set(state);
    },
    setNoneLocationToCurrent(state: Istate) {
      state.location.use = undefined;
      state.location.address = "";
      storedState.set(state);
    },
    setLocationToSelected(state: Istate, action: any) {
      const { payload } = action;
      state.location = {
        use: "selected",
        searchCoordinates: {
          longitude: !!payload?.searchCoordinates?.longitude
            ? payload.searchCoordinates?.longitude
            : undefined,
          latitude: !!payload?.searchCoordinates?.latitude
            ? payload.searchCoordinates?.latitude
            : undefined,
        },
        address: !!payload.address ? payload.address : "",
        formatted_address: !!payload.formatted_address
          ? payload.formatted_address
          : "",
      };
      storedState.set(state);
    },
    clearSettedLocation(state: Istate) {
      state.location = {
        use: undefined,
        searchCoordinates: {
          longitude: undefined,
          latitude: undefined,
        },
        address: "",
        formatted_address: "",
      };
      storedState.set(state);
    },
    setCurrentStep(state: Istate, action: any) {
      state.step = action.payload;
      storedState.set(state);
    },
    clearFinderFilters(state: Istate) {
      state.filters = {
        additionalInfoCodes: [],
        workshopBrands: "",
        workshopName: "",
        workshopFranchise: "",
      };
      storedState.set(state);
    },
    setFinderFilters(
      state: Istate,
      action: {
        payload: {
          additionalInfoCodes?: string[];
          workshopBrands?: string;
          workshopName?: string;
          workshopFranchise?: string;
          hasWarranty?: boolean | null;
        };
      }
    ) {
      const { payload } = action;
      if (payload.additionalInfoCodes)
        state.filters.additionalInfoCodes = payload.additionalInfoCodes;
      if (payload.workshopBrands)
        state.filters.workshopBrands = payload.workshopBrands;
      if (payload.workshopName)
        state.filters.workshopName = payload.workshopName;
      if (payload.hasWarranty) state.filters.hasWarranty = payload.hasWarranty;
      if (payload.hasWarranty === null) state.filters.hasWarranty = null;
      if (payload.workshopFranchise)
        state.filters.workshopFranchise = payload.workshopFranchise;

      storedState.set(state);
    },
    setAdditionalInfoCodesFilter(state: Istate, action: { payload: string[] }) {
      state.filters.additionalInfoCodes = action.payload;
      storedState.set(state);
    },
    setVehicleBrandFilter(state: Istate, action: { payload: string }) {
      state.filters.workshopBrands = action.payload;
      storedState.set(state);
    },
    setWorkshopNameFilter(state: Istate, action: { payload: string }) {
      state.filters.workshopName = action.payload;
      storedState.set(state);
    },
    setFranchiseFilter(state: Istate, action: { payload: string }) {
      state.filters.workshopFranchise = action.payload;
      storedState.set(state);
    },
    setSort(state: Istate, action: { payload: TSortCriteria }) {
      state.sort = action.payload;
      storedState.set(state);
    },
    storeKmValue(state: Istate, action: { payload: number }) {
      state.km = action.payload;
      storedState.set(state);
    },
    openContactDrawer(state: Istate, action: { payload: string }) {
      state.contactWorkshopId = action.payload;
      storedState.set(state);
    },
    closeContactDrawer(state: Istate) {
      state.contactWorkshopId = undefined;
      storedState.set(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      storeFetchServicesCarousel.fulfilled,
      (state, { payload }) => {
        state.catalog.services.values = payload;
        state.catalog.services.status = "succeeded";
        state.catalog.services.error = undefined;
        storedState.set(state);
      }
    );

    builder.addCase(storeFetchServicesCarousel.pending, (state, { }) => {
      state.catalog.services.values = [];
      state.catalog.services.status = "loading";
      state.catalog.services.error = undefined;
      storedState.set(state);
    });

    builder.addCase(storeFetchServicesCarousel.rejected, (state, { error }) => {
      state.catalog.services.status = "failed";
      state.catalog.services.error = error.message;
      storedState.set(state);
    });

    builder.addCase(
      storeFetchPackagesCombination.fulfilled,
      (state, { payload }) => {
        state.catalog.packages.values = payload;
        state.catalog.packages.status = "succeeded";
        state.catalog.packages.error = undefined;
        storedState.set(state);
      }
    );

    builder.addCase(storeFetchPackagesCombination.pending, (state, { }) => {
      state.catalog.packages.values = [];
      state.catalog.packages.status = "loading";
      state.catalog.packages.error = undefined;
      storedState.set(state);
    });

    builder.addCase(
      storeFetchPackagesCombination.rejected,
      (state, { error }) => {
        state.catalog.packages.status = "failed";
        state.catalog.packages.error = error.message;
        storedState.set(state);
      }
    );

    builder.addCase(storeFetchVehicleBrands.fulfilled, (state, { payload }) => {
      state.catalog.vehicleBrands = {
        values: payload,
        status: "succeeded",
        error: undefined,
      };
      storedState.set(state);
    });

    builder.addCase(storeFetchVehicleBrands.pending, (state, { }) => {
      state.catalog.vehicleBrands = {
        values: [],
        status: "loading",
        error: undefined,
      };
      storedState.set(state);
    });

    builder.addCase(storeFetchVehicleBrands.rejected, (state, { error }) => {
      state.catalog.vehicleBrands = {
        values: state.catalog?.vehicleBrands || [],
        status: "failed",
        error: error.message,
      };
      storedState.set(state);
    });

    builder.addCase(
      storeFetchAdditionalInfoCodes.fulfilled,
      (state, { payload }) => {
        state.catalog.additionalInfoCodes = {
          values: payload,
          status: "succeeded",
          error: undefined,
        };
        storedState.set(state);
      }
    );

    builder.addCase(storeFetchAdditionalInfoCodes.pending, (state, { }) => {
      state.catalog.additionalInfoCodes = {
        values: [],
        status: "loading",
        error: undefined,
      };
      storedState.set(state);
    });

    builder.addCase(
      storeFetchAdditionalInfoCodes.rejected,
      (state, { error }) => {
        state.catalog.additionalInfoCodes = {
          values: state.catalog?.additionalInfoCodes || [],
          status: "failed",
          error: error.message,
        };
        storedState.set(state);
      }
    );
  },
});

export const {
  setSelectedCriteria,
  setSelectedPackage,
  clearSelectedPackage,
  setSelectedService,
  unsetSelectedService,
  clearSelectedServices,
  toogleSelectedServices,
  setReasonsSelected,
  setLocationToSelected,
  setLocationToCurrent,
  setNoneLocationToCurrent,
  clearSettedLocation,
  setCurrentStep,
  clearFinderFilters,
  setFinderFilters,
  setAdditionalInfoCodesFilter,
  setVehicleBrandFilter,
  setWorkshopNameFilter,
  setFranchiseFilter,
  setSort,
  storeKmValue,
  openContactDrawer,
  closeContactDrawer
} = WorkshopFilteredSlice.actions;
export default WorkshopFilteredSlice.reducer;
